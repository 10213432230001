@mixin tiny_mobile {
    @media screen and (max-width: 350px) {
        @content;
    }
}

@mixin mobile {
    @media screen and (max-width: 600px) {
        @content;
    }
}

@mixin tablet {
    @media screen and (max-width: 1000px) {
        @content;
    }
}

@mixin not_mobile {
    @media screen and (min-width: 600px) {
        @content;
    }
}