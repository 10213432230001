.c-labeled-grid__grid {

    & > :not([dontallowdrag]) {
        &:not(.isDragging) {
            transition: transform .2s cubic-bezier(0.4, 0.0, 0.2, 1);
        }
    }

    & > .isDragging {
        z-index: 1;
        pointer-events: none;
        opacity: .75;
    }
}