@import '@vm/mobile';

$background: #0e0e0e;
$color: #ffffff; //rgba(var(--theme-background-end-contrast))

.ng-tooltip {
    position: absolute;
    font-size: .8rem;
    font-weight: 600;
    text-align: center;
    color: $color;
    padding: .4rem .6rem;
    background: $background;
    border-radius: 4px;
    z-index: 1000;
    opacity: 0;
    transform: scale(.8);
    transform-origin: 50% 100%;
    animation: popout .2s ease;
    pointer-events: none;

    @include mobile {
        display: none !important;
    }
}
.ng-tooltip {
    &:after {
        content: "";
        position: absolute;
        border-style: solid;
    }
}
.ng-tooltip-top {
    transform-origin: 50% 100%;

    &:after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-color: $background transparent transparent transparent;
    }
}
.ng-tooltip-bottom {
    transform-origin: 50% 0%;

    &:after {
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-color: transparent transparent $background transparent;
    }
}
.ng-tooltip-left {
    transform-origin: 100% 50%;

    &:after {
        top: 50%;
        left: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-color: transparent transparent transparent $background;
    }
}
.ng-tooltip-right {
    transform-origin: 0% 50%;

    &:after {
        top: 50%;
        right: 100%;
        margin-top: -5px;
        border-width: 5px;
        border-color: transparent $background transparent transparent;
    }
}
.ng-tooltip-show {
    opacity: 1;
    animation: popin .3s ease forwards;
}

@keyframes popin {
    0% {
        opacity: 0;
        transform: scale(.8);
    }
    20% {
        opacity: 1;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes popout {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(.9);
    }
}