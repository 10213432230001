// Fonts
// 400 - Regular
// 500 - Semi
// 600 - Bold
// 700 - Extra Bold

// REGULAR
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

// SEMI
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-MediumItalic.ttf');
    font-weight: 500;
    font-style: italic;
}

// SEMI
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

// BOLD
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Core Font';
    src: url('/assets/fonts/Poppins-BoldItalic.ttf');
    font-weight: 700;
    font-style: italic;
}

* {
    font-family: 'Core Font', sans-serif;
    line-height: 1.1em;
}