:root {
    // General Settings
    --theme-border-radius: .5rem;
    --theme-padding-regular: .5rem;
    --theme-glow: 0 .2rem .5rem 0 rgba(var(--theme-primary), .3);
    --theme-glow-intense: 0 .1rem .3rem 0 rgba(var(--theme-primary), .4);
    --theme-animation-long: .3s cubic-bezier(0.4, 0.0, 0.2, 1);
    --theme-animation-mid: .2s ease;
    --theme-animation-short: .1s ease;
    --theme-icon-size: 1.25rem;
    
    // Theme Settings
    --theme-background: 255, 255, 255;
    --theme-background-contrast: 30, 30, 30;
    --theme-shadow: rgba(0, 0, 0, .2);

    // --theme-background-alt: 237, 240, 245;
    --theme-background-alt: 235, 238, 240;
    // --theme-background-alt: 217, 220, 220;
    --theme-background-alt-contrast: 30, 30, 30;

    // Old dark board during light-mode - potentially make optional for pro users?
    // --theme-board: 30, 30, 30;
    // --theme-board-contrast: 255, 255, 255;
    // --theme-board-grid: .15;
    --theme-board: 221, 224, 230;
    --theme-board-contrast: 30, 20, 40;
    --theme-board-grid: .5;
    --theme-board-radius: .5rem;

    // --theme-primary: 0, 161, 135; // Green
    // --theme-primary: 247, 66, 87; // Red
    // --theme-primary: 0, 89, 255; // Blue
    --theme-primary: 247, 66, 87;
    --theme-primary-contrast: 255, 255, 255;

    --theme-secondary: 60, 205, 135;
    --theme-secondary-contrast: 255, 255, 255;

    // Pink:    255, 45, 143
    // Purple:  130, 122, 245
    // Green:   90, 192, 155
    // Brown:   140, 100, 100
    // Blue:    50, 90, 250
    // Orange:  250, 175, 60
    // Red:     250, 40, 90

    // Ionic Overrides
    --ion-background-color: rgb(var(--theme-background-alt));
    --ion-toolbar-background: rgb(var(--theme-background));

    @media (prefers-color-scheme: dark) {
        --theme-background: 52, 55, 65;
        --theme-background-contrast: 255,255,255;
        --theme-shadow: rgba(0,0,0,0.3);

        --theme-background-alt: 39, 41, 50;
        --theme-background-alt-contrast: 255,255,255;

        --theme-board: 33, 34, 41;
        --theme-board-contrast: 255,255,255;
        --theme-board-grid: .15;
    }
}

app-action-input, app-action-checkbox, app-action-dropdown  {
    --action-secondary: rgba(var(--theme-background-alt)) !important;
    --action-secondary-contrast: rgba(var(--theme-background-alt-contrast)) !important;
}