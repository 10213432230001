/* width */
::-webkit-scrollbar {
    width: .3rem;
    height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: rgba(var(--theme-background-contrast), 0.1);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(var(--theme-background-contrast), 0.2);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(var(--theme-background-contrast), 0.5);
}