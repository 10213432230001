@import '@vm/mobile';

@mixin quill_mention {
    background: rgba(var(--theme-primary), .2);
    color: rgba(var(--theme-primary), 1);
    padding: 0.1rem 0.3rem;
    border-radius: calc(var( --theme-border-radius) * .5);
    font-weight: 600;
    transition: var(--theme-animation-short);
    margin: 0.15rem 0;
    margin-right: auto;
    max-width: 10rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
        cursor: pointer;
        background: rgba(var(--theme-primary), 1);
        color: rgba(var(--theme-primary-contrast), 1);
    }

    &.selected {
        box-shadow: 0 0 0 .1rem rgba(var(--theme-primary), .5) inset;
    }

    span {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ql-mention-denotation-char {
        display: none;
    }
}

@mixin quill_strong {
    font-weight: 600;
}

@mixin quill_list {
    padding-left: 0;

    li {
        padding-left: 0.5rem;

        &::before, &::marker {
            font-weight: 500;
            color: rgba(var(--theme-primary), 1);
        }
    }
}

.c-chat-input {
    .ql-editor {
        max-height: 12.5rem;
        line-height: 1em;

        @include mobile {
            font-size: .9rem;
        }

        &.ql-blank::before {
            font-style: normal;
            color: rgba(var(--theme-background-alt-contrast), .5)
        }

        strong {
            @include quill_strong();
        }

        ul, ol {
            @include quill_list();
        }
    }

    .mention {
        @include quill_mention();
        display: inline-flex;
    }

    .ql-mention-list-container {
        position: absolute;
        visibility: visible;
        top: -48px;
        left: 15px;
        background-color: rgba(var(--theme-background));
        border-radius: var(--theme-border-radius);
        box-shadow: 0 0.1rem 0.25rem 0 var(--theme-shadow);
        left: 0 !important;
        width: 100%;
        bottom: 115% !important;
        top: auto !important;
        z-index: 1;

        .ql-mention-list {
            list-style: none;
            margin: 0;
            padding: 0.5rem 0.75rem;
            display: flex;
            flex-direction: column;

            .ql-mention-list-item {
                @include quill_mention();
                margin-bottom: 0.2rem;
            } 
        }
    }
}

.c-chat-message {
    font-size: .8rem;

    @include mobile {
        font-size: .9rem;
    }

    &__content {
        * {
            line-height: 1.2em;
        }

        & p {
            padding: 0;
            margin: 0;
        }

        .mention {
            color: rgba(var(--theme-primary), 1);
            border-radius: calc(var( --theme-border-radius) * .5);
            font-weight: 600;
            transition: var(--theme-animation-short);

            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }

            .ql-mention-denotation-char {
                display: none;
            }
        }

        strong {
            @include quill_strong();
        }

        ul, ol {
            @include quill_list();
            padding-left: 1.5em;
            margin: 0;

            li {
                padding-left: 0;
            }
        }
    }
}