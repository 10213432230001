@import '@vm/mobile';

ion-modal {
    .modal-wrapper {
        --ion-background-color: rgb(var(--theme-background));
        --background: rgb(var(--theme-background));
    }
}

@include not_mobile {
    // Make the modal fit it's content within reason.
    ion-modal.auto-height {
        &.bottom {
            align-items: flex-end;
        }
    
        --height: auto;
    
        .ion-page {
            position: relative;
            display: block;
            contain: content;
    
            .inner-content {
                max-height: 80vh;
                overflow: auto;
                padding: 10px;
            }
        }
    }

    // ion-modal.auto-height {
    //     .modal-wrapper {
    //         --width: auto;
    //         --max-width: 600px;
    //     }
    // }
}

@include mobile {
    ion-modal.bottom-sheet {
        --height: auto;
        display: flex;
        flex-direction: column;
        justify-content: end;
        contain: unset;
    
        .modal-wrapper {
            position: relative;
            top: auto;
            --max-height: 100%;
            --height: auto;
    
            .ion-page {
                top: auto;
                height: auto;
                display: block;
                bottom: 0;
                contain: unset;
                position: relative;
            }
        }
    }
}

// Android / Material Overrides
.md {
    ion-list-header {
        font-size: .8rem;
        min-height: 2rem;
        opacity: .5;
    }
    
    ion-item {
        font-size: .9rem;
        --min-height: 2.5rem;
    }
}

.ios {
    ion-list-header {
        font-size: .8rem;
        min-height: 2rem;
        opacity: .5;
    }
    
    ion-item {
        font-size: .9rem;
        --min-height: 2.5rem;
    }
}
